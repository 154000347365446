<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card
			v-if="($user.details().type === distributor || admin.includes($user.details().type)) && !isJnsView"
			v-show="refsLoaded && showCard('bo-join')"
			ref="bo-join"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-join
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('join_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-join"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('join_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('purchase-products')"
			ref="purchase-products"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.purchase-products
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('purchase_products_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="purchase-products"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('purchase_products_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('retail-price')"
			ref="retail-price"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.retail-price
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('retail_price_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="retail-price"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('retail_price_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bv')"
			ref="bv"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bv
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bv_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bv"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bv_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('accumulate-bv')"
			ref="accumulate-bv"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.accumulate-bv
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('accumulate_bv_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="accumulate-bv"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('accumulate_bv_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('lose-bv')"
			ref="lose-bv"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.lose-bv
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('lose_bv_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="lose-bv"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('lose_bv_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('entry-level')"
			ref="entry-level"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.entry-level
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('entry_level_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="entry-level"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('entry_level_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('to-be-active')"
			ref="to-be-active"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.to-be-active
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('to_be_active_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="to-be-active"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('to_be_active_answer', { points: pointsToActivate }) }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('activation-weeks')"
			ref="activation-weeks"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.activation-weeks
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('activation_weeks_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="activation-weeks"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('activation_weeks_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('qualifying-period')"
			ref="qualifying-period"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.qualifying-period
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('qualifying_period_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="qualifying-period"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('qualifying_period_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('associate-rank')"
			ref="associate-rank"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.associate-rank
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('associate_rank_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="associate-rank"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('associate_rank_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('purchase-difference')"
			ref="purchase-difference"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.purchase-difference
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('purchase_difference_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="purchase-difference"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('purchase_difference_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('referral-bonus')"
			ref="referral-bonus"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.referral-bonus
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('referral_bonus_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="referral-bonus"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('referral_bonus_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('team-commission')"
			ref="team-commission"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.team-commission
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('team_commission_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="team-commission"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('team_commission_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('leadership-qualification')"
			ref="leadership-qualification"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.leadership-qualification
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('leadership_qualification_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="leadership-qualification"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('leadership_qualification_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('royalty-bonus')"
			ref="royalty-bonus"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.royalty-bonus
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('royalty_bonus_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="royalty-bonus"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('royalty_bonus_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('lifestyle-bonus')"
			ref="lifestyle-bonus"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.lifestyle-bonus
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('lifestyle_bonus_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="lifestyle-bonus"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('lifestyle_bonus_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('inactive')"
			ref="inactive"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.inactive
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('inactive_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="inactive"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('inactive_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('quarterly-bonus')"
			ref="quarterly-bonus"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.quarterly-bonus
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('quarterly_bonus_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="quarterly-bonus"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('quarterly_bonus_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('retirement-plan')"
			ref="retirement-plan"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.retirement-plan
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('retirement_plan_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="retirement-plan"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('retirement_plan_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import { distributor, admin } from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';
import { POINTS_TO_ACTIVATE } from '@/settings/Points';

export default {
	name: 'FaqCompPlan',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			distributor,
			admin,
			pointsToActivate: POINTS_TO_ACTIVATE,
		};
	},
	computed: {
		isJnsView() {
			return this.isJns();
		},
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
