export default {
	data() {
		return {
			refsLoaded: false,
		};
	},
	mounted() {
		this.refsLoaded = true;
	},
	methods: {
		hasSearchTerm(ref, searchTerm) {
			return this.$refs[ref].textContent.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
		},
	},
};
