<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('when-get-products')"
			ref="when-get-products"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.when-get-products
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('when_get_products_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="when-get-products"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('when_get_products_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('second-day-shipping')"
			ref="second-day-shipping"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.second-day-shipping
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('second_day_shipping_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="second-day-shipping"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('second_day_shipping_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('shipping-services')"
			ref="shipping-services"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.shipping-services
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('shipping_services_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="shipping-services"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('shipping_services_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('products-outside')"
			ref="products-outside"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-outside
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_outside_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-outside"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('products_outside_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!isJnsView"
			v-show="refsLoaded && showCard('purchase-outside')"
			ref="purchase-outside"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.purchase-outside
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('purchase_outside_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="purchase-outside"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('purchase_outside_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('tracking-number')"
			ref="tracking-number"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.tracking-number
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('tracking_number_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="tracking-number"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<p v-html="translate('tracking_number_answer')" />
						<ol>
							<li>{{ translate('tracking_number_answer_1') }}</li>
							<li>{{ translate('tracking_number_answer_2') }}</li>
						</ol>
						{{ translate('tracking_number_answer_3') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('order-shipping-method')"
			ref="order-shipping-method"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.order-shipping-method
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('order_shipping_method_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="order-shipping-method"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text
						v-if="isJnsView"
						v-html="translate('order_shipping_method_answer_jns')" />
					<b-card-text
						v-else
						v-html="translate('order_shipping_method_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('non-contiguos-shipping')"
			ref="non-contiguos-shipping"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.non-contiguos-shipping
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('non_contiguos_states_shipping_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="non-contiguos-shipping"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('non_contiguos_states_shipping_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('mistake-cahspayment')"
			ref="mistake-cahspayment"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.mistake-cahspayment
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('mistake_cash_payment_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="mistake-cahspayment"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('mistake_cash_payment_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-v-fill')"
			ref="bo-v-fill"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-v-fill
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-v-fill"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('v-fill-processed')"
			ref="v-fill-processed"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.v-fill-processed
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_processed_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="v-fill-processed"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_processed_answer_1') }} <br><br>
						{{ translate('v_fill_processed_answer_2') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-verification-phone')"
			ref="bo-verification-phone"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-verification-phone
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('verification_phone_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-verification-phone"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('verification_phone_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import { distributor, admin } from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';

export default {
	name: 'FaqOrdersShipping',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			distributor,
			admin,
		};
	},
	computed: {
		isJnsView() {
			return this.isJns();
		},
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
