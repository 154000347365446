<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-invitation-expiration')"
			ref="bo-invitation-expiration"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-invitation-expiration
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('invitation_expiration_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-invitation-expiration"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('invitation_expiration_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-invitation-same-person')"
			ref="bo-invitation-same-person"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-invitation-same-person
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('invitation_same_person_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-invitation-same-person"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('invitation_same_person_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-invitation-more-sponsor')"
			ref="bo-invitation-more-sponsor"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-invitation-more-sponsor
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('invitation_more_one_sponsor_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-invitation-more-sponsor"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('invitation_more_one_sponsor_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-inviting-member')"
			ref="bo-inviting-member"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-inviting-member
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('inviting_member_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-inviting-member"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('inviting_member_answer_1') }}</li>
							<li>{{ translate('inviting_member_answer_2') }}</li>
							<li>{{ translate('inviting_member_answer_3') }}</li>
							<li>{{ translate('inviting_member_answer_4') }}</li>
							<li>{{ translate('inviting_member_answer_5') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<!-- <b-card
			v-if="$user.details().type === preDistributor || admin.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-managing-invitations')"
			ref="bo-managing-invitations"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-managing-invitations
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('managing_invitations_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-managing-invitations"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('managing_invitations_answer_1') }}</li>
							<li>{{ translate('managing_invitations_answer_2') }}</li>
							<li>{{ translate('managing_invitations_answer_3') }}</li>
							<li>{{ translate('managing_invitations_answer_4') }}</li>
							<li>{{ translate('managing_invitations_answer_5') }}</li>
							<li>{{ translate('managing_invitations_answer_6') }}</li>
							<li>{{ translate('managing_invitations_answer_7') }}</li>
							<li>{{ translate('managing_invitations_answer_8') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<b-card
			v-show="refsLoaded && showCard('bo-email')"
			ref="bo-email"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-email
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_change_email_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-email"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_change_email_answer_1') }}</li>
							<li>{{ translate('bo_change_email_answer_2') }}</li>
							<li>{{ translate('bo_change_email_answer_3') }}</li>
							<li>{{ translate('bo_change_email_answer_4') }}</li>
							<li>{{ translate('bo_change_email_answer_5') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<!-- <b-card
			v-show="refsLoaded && showCard('bo-changing-mob-number')"
			ref="bo-changing-mob-number"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-changing-mob-number
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('change_number_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-changing-mob-number"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('change_number_answer_1') }}</li>
							<li>{{ translate('change_number_answer_2') }}</li>
							<li>{{ translate('change_number_answer_3') }}</li>
							<li>{{ translate('change_number_answer_4') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card> -->

		<b-card
			v-show="refsLoaded && showCard('bo-sms-notification')"
			ref="bo-sms-notification"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-sms-notification
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('change_sms_notif_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-sms-notification"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('change_sms_notif_answer_1') }}</li>
							<li>{{ translate('change_sms_notif_answer_2') }}</li>
							<li>{{ translate('change_sms_notif_answer_3') }}</li>
							<li>{{ translate('change_sms_notif_answer_4') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-replicated')"
			ref="bo-replicated"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-replicated
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_update_replicated_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-replicated"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_update_replicated_answer_1') }}</li>
							<li>{{ translate('bo_update_replicated_answer_2') }}</li>
							<li>{{ translate('bo_update_replicated_answer_3') }}</li>
							<li>{{ translate('bo_update_replicated_answer_4') }}</li>
							<li>{{ translate('bo_update_replicated_answer_5') }}</li>
							<li>{{ translate('bo_update_replicated_answer_6') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-sign-dist')"
			ref="bo-sign-dist"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-sign-dist
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_sign_distributor_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-sign-dist"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_sign_distributor_answer_1') }}</li>
							<ul>
								<li>{{ translate('bo_sign_distributor_answer_1_1') }}</li>
								<li>{{ translate('bo_sign_distributor_answer_1_2') }}</li>
								<li>{{ translate('bo_sign_distributor_answer_1_3') }}</li>
							</ul>
							<li>{{ translate('bo_sign_distributor_answer_2') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_3') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_4') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_5') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_6') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_7') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_8') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_9') }}</li>
							<li>{{ translate('bo_sign_distributor_answer_10') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-shipping-address')"
			ref="bo-shipping-address"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-shipping-address
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_change_shipping_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-shipping-address"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_change_shipping_answer_1') }}</li>
							<li>{{ translate('bo_change_shipping_answer_2') }}</li>
							<li>{{ translate('bo_change_shipping_answer_3') }}</li>
							<li>{{ translate('bo_change_shipping_answer_5') }}</li>
							<li>{{ translate('bo_change_shipping_answer_6') }}</li>
							<li>{{ translate('bo_change_shipping_answer_7') }}</li>
							<ul>
								<li>{{ translate('bo_change_shipping_answer_7_1') }}</li>
							</ul>
							<li>{{ translate('bo_change_shipping_answer_8') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-cc')"
			ref="bo-cc"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-cc
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_change_cc_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-cc"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_change_cc_answer_1') }}</li>
							<li>{{ translate('bo_change_cc_answer_2') }}</li>
							<li>{{ translate('bo_change_cc_answer_3') }}</li>
							<li>{{ translate('bo_change_cc_answer_4') }}</li>
							<li>{{ translate('bo_change_cc_answer_5') }}</li>
							<li>{{ translate('bo_change_cc_answer_6') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-download-materials')"
			ref="bo-download-materials"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-download-materials
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('download_materials_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-download-materials"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('download_materials_answer_1') }}</li>
							<li>{{ translate('download_materials_answer_2') }}</li>
							<li>{{ translate('download_materials_answer_3') }}</li>
							<li>{{ translate('download_materials_answer_4') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-power-leg')"
			ref="bo-power-leg"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-power-leg
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('power_leg_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-power-leg"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('power_leg_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-if="!customerRoles.includes($user.details().type)"
			v-show="refsLoaded && showCard('bo-default-leg')"
			ref="bo-default-leg"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-default-leg
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_default_leg_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-default-leg"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_default_leg_answer_1') }}</li>
							<li>{{ translate('bo_default_leg_answer_2') }}</li>
							<li>{{ translate('bo_default_leg_answer_3') }}</li>
							<li>{{ translate('bo_default_leg_answer_4') }}</li>
							<li>{{ translate('bo_default_leg_answer_5') }}</li>
							<li>{{ translate('bo_default_leg_answer_6') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-v-fill')"
			ref="bo-v-fill"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-v-fill
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('v_fill_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-v-fill"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('v_fill_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-autoship-disable')"
			ref="bo-autoship-disable"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-autoship-disable
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_disable_autoship_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-autoship-disable"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_disable_autoship_answer_1') }}</li>
							<li>{{ translate('bo_disable_autoship_answer_2') }}</li>
							<li>{{ translate('bo_disable_autoship_answer_3') }}</li>
							<li>{{ translate('bo_disable_autoship_answer_4', { points: pointsToActivate }) }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-autoship-change')"
			ref="bo-autoship-change"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-autoship-change
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_change_autoship_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-autoship-change"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_change_autoship_answer_1') }}</li>
							<li>{{ translate('bo_change_autoship_answer_2') }}</li>
							<li>{{ translate('bo_change_autoship_answer_3') }}</li>
							<li>{{ translate('bo_change_autoship_answer_4') }}</li>
						</ol>
						{{ translate('bo_change_autoship_answer_5') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-purchase')"
			ref="bo-purchase"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-purchase
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_store_purchase_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-purchase"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ol>
							<li>{{ translate('bo_store_purchase_answer_1') }}</li>
							<li>{{ translate('bo_store_purchase_answer_2') }}</li>
							<li>{{ translate('bo_store_purchase_answer_3') }}</li>
							<li>{{ translate('bo_store_purchase_answer_4') }}</li>
							<li>{{ translate('bo_store_purchase_answer_5') }}</li>
							<li>{{ translate('bo_store_purchase_answer_6') }}</li>
							<li>{{ translate('bo_store_purchase_answer_7') }}</li>
							<li>{{ translate('bo_store_purchase_answer_8') }}</li>
							<li>{{ translate('bo_store_purchase_answer_9') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-see-orders')"
			ref="bo-see-orders"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-see-orders
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_see_orders_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-see-orders"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<p>{{ translate('bo_see_orders_answer') }}</p>
						<ol>
							<li>{{ translate('bo_see_orders_answer_1') }}</li>
							<li>{{ translate('bo_see_orders_answer_2') }}</li>
							<li>{{ translate('bo_see_orders_answer_3') }}</li>
							<li>{{ translate('bo_see_orders_answer_4') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-referred')"
			ref="bo-referred"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-referred
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bo_difference_referred_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-referred"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('bo_difference_referred_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-become-member')"
			ref="bo-become-member"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-become-member
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('become_member_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-become-member"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<p>{{ translate('become_member_answer') }}</p>
						<ol>
							<li>{{ translate('become_member_answer_1') }}</li>
							<li>{{ translate('become_member_answer_2') }}</li>
							<li>{{ translate('become_member_answer_3') }}</li>
							<li>{{ translate('become_member_answer_4') }}</li>
							<li>{{ translate('become_member_answer_5') }}</li>
							<li>{{ translate('become_member_answer_6') }}</li>
						</ol>
						{{ translate('become_member_answer_7') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('tips-smooth-payment')"
			ref="tips-smooth-payment"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.tips-smooth-payment
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('tips_smooth_payment_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="tips-smooth-payment"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<p>{{ translate('tips_smooth_payment_answer') }}</p>
						<ul>
							<li>{{ translate('tips_smooth_payment_answer_1') }}</li>
							<li>{{ translate('tips_smooth_payment_answer_2') }}</li>
							<li>{{ translate('tips_smooth_payment_answer_3') }}</li>
							<li>{{ translate('tips_smooth_payment_answer_4') }}</li>
							<li>{{ translate('tips_smooth_payment_answer_5') }}</li>
							<li>{{ translate('tips_smooth_payment_answer_6') }}</li>
						</ul>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bo-verification-phone')"
			ref="bo-verification-phone"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bo-verification-phone
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('verification_phone_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bo-verification-phone"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('verification_phone_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import {
	customerRoles,
	preDistributor,
	admin,
} from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';
import { AUTOSHIP_MINIMUM_POINTS } from '@/settings/Points';

export default {
	name: 'FaqBackofficeProcedure',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			customerRoles,
			preDistributor,
			admin,
			pointsToActivate: AUTOSHIP_MINIMUM_POINTS,
		};
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
