<template>
	<div class="row">
		<div class="col">
			<div class="alert alert-secondary mb-3 py-3">
				<div class="row">
					<div class="col">
						<div class="form-group my-0">
							<label
								for="searchTerm"
								class="label">
								{{ translate('faq_search_label') }}
							</label>
							<input
								id="search-term"
								v-model="searchTerm"
								:placeholder="translate('faq_search_placeholder')"
								autocomplete="off"
								name="searchTerm"
								type="text"
								class="form-control">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Faq } from '@/translations';
import { SEARCH_CONFIG } from '@/settings/Faq';

export default {
	name: 'SearchInput',
	messages: [Faq],
	data() {
		return {
			searchTerm: '',
		};
	},
	watch: {
		language() {
			this.searchTerm = '';
			this.$forceUpdate();
		},
	},
	mounted() {
		// we add the searchTerm watcher until after the searchTerm is loaded from url
		this.searchTerm = this.$route.query[SEARCH_CONFIG.queryKey];
		this.$watch('searchTerm', (newVal) => this.$emit('submit', newVal));
	},
};
</script>
