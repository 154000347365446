<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card
			v-show="refsLoaded && showCard('bran-reimagined')"
			ref="bran-reimagined"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-reimagined
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_reimagined_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-reimagined"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_reimagined_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-benefits')"
			ref="bran-benefits"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-benefits
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_benefits_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-benefits"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_benefits_answer') }}
						<p v-html="translate('bran_benefits_answer_1')" />
						<ul>
							<li>{{ translate('bran_benefits_answer_1_1') }}</li>
							<li>{{ translate('bran_benefits_answer_1_2') }}</li>
							<li>{{ translate('bran_benefits_answer_1_3') }}</li>
						</ul>
						<p v-html="translate('bran_benefits_answer_2')" />
						<ul>
							<li>{{ translate('bran_benefits_answer_2_1') }}</li>
							<li>{{ translate('bran_benefits_answer_2_2') }}</li>
							<li>{{ translate('bran_benefits_answer_2_3') }}</li>
						</ul>
						<p v-html="translate('bran_benefits_answer_3')" />
						<ul>
							<li>{{ translate('bran_benefits_answer_3_1') }}</li>
							<li>{{ translate('bran_benefits_answer_3_2') }}</li>
							<li>{{ translate('bran_benefits_answer_3_3') }}</li>
						</ul>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-effect-last')"
			ref="bran-effect-last"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-effect-last
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_effect_last_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-effect-last"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_effect_last_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-time')"
			ref="bran-time"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-time
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_best_time_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-time"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_best_time_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-vegan')"
			ref="bran-vegan"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-vegan
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_vegan_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-vegan"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_vegan_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-gmo')"
			ref="bran-gmo"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-gmo
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_gmo_free_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-gmo"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_gmo_free_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-gluten')"
			ref="bran-gluten"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-gluten
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_gluten_free_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-gluten"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_gluten_free_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-results')"
			ref="bran-results"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-results
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_results_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-results"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('bran_results_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-keep-awake')"
			ref="zlem-keep-awake"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-keep-awake
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_keep_awake_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-keep-awake"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_keep_awake_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-different')"
			ref="zlem-different"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-different
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_different_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-different"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_different_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-side-effect')"
			ref="zlem-side-effect"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-side-effect
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_side_effects_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-side-effect"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_side_effects_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-take-more')"
			ref="zlem-take-more"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-take-more
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_take_more_one_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-take-more"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_take_more_one_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('bran-zlem')"
			ref="bran-zlem"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.bran-zlem
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('bran_zlem_combine_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="bran-zlem"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('bran_zlem_combine_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-children')"
			ref="zlem-children"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-children
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_children_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-children"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_children_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-sleep')"
			ref="zlem-sleep"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-sleep
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_sleep_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-sleep"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_sleep_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-weight')"
			ref="zlem-weight"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-weight
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_weight_loss_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-weight"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_weight_loss_answer') }}
						<ul>
							<li>{{ translate('zlem_weight_loss_answer_1') }}</li>
							<li>{{ translate('zlem_weight_loss_answer_2') }}</li>
							<li>{{ translate('zlem_weight_loss_answer_3') }}</li>
							<li>{{ translate('zlem_weight_loss_answer_4') }}</li>
						</ul>
						{{ translate('zlem_weight_loss_answer_5') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-results')"
			ref="zlem-results"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-results
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_results_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-results"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('zlem_results_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('zlem-allergens')"
			ref="zlem-allergens"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.zlem-allergens
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('zlem_allergens_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="zlem-allergens"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('zlem_allergens_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-soy')"
			ref="products-soy"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-soy
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_soy_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-soy"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_soy_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('uuth-collagen')"
			ref="uuth-collagen"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.uuth-collagen
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('uuth_collagen_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="uuth-collagen"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('uuth_collagen_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('displayed-date-bran')"
			ref="displayed-date-bran"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.displayed-date-bran
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('displayed_date_bran_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="displayed-date-bran"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('displayed_date_bran_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-pregnancy')"
			ref="products-pregnancy"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-pregnancy
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_in_pregnancy_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-pregnancy"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_in_pregnancy_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-medication')"
			ref="products-medication"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-medication
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_other_medication_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-medication"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_other_medication_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('snap-bpas')"
			ref="snap-bpas"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.snap-bpas
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('snap_bpas_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="snap-bpas"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('snap_bpas_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-fda')"
			ref="products-fda"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-fda
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_fda_approved_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-fda"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_fda_approved_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-temperature')"
			ref="products-temperature"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-temperature
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_temperature_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-temperature"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_temperature_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('snaps-25')"
			ref="snaps-25"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.snaps-25
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('snaps_25_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="snaps-25"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('snaps_25_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-manufactured')"
			ref="products-manufactured"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-manufactured
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_manufactured_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-manufactured"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_manufactured_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-formulated')"
			ref="products-formulated"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-formulated
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_formulated_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-formulated"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_formulated_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>

		<b-card
			v-show="refsLoaded && showCard('products-drug')"
			ref="products-drug"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.products-drug
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('products_drug_test_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="products-drug"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('products_drug_test_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
import { distributor, admin } from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';

export default {
	name: 'FaqProductInformation',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			distributor,
			admin,
		};
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
