<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<b-card
			v-show="refsLoaded && showCard('configure-ewallet')"
			ref="configure-ewallet"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.configure-ewallet
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('configure_ewallet_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="configure-ewallet"
				visible
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('configure_ewallet_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('tips-smooth-ewallet')"
			ref="tips-smooth-ewallet"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.tips-smooth-ewallet
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('tips_smooth_ewallet_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="tips-smooth-ewallet"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						<ul>
							<li>{{ translate('tips_smooth_ewallet_answer_1') }}</li>
							<li>{{ translate('tips_smooth_ewallet_answer_2') }}</li>
							<li>{{ translate('tips_smooth_ewallet_answer_3') }}</li>
						</ul>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('withdraw-commissions')"
			ref="withdraw-commissions"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.withdraw-commissions
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('withdraw_commissions_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="withdraw-commissions"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('withdraw_commissions_answer') }}
						<ol>
							<li>{{ translate('withdraw_commissions_answer_1') }}</li>
							<ol type="a">
								<li v-html="translate('withdraw_commissions_answer_1_1')" />
								<li>{{ translate('withdraw_commissions_answer_1_2') }}</li>
							</ol>
							<li>{{ translate('withdraw_commissions_answer_2') }}</li>
							<li>{{ translate('withdraw_commissions_answer_3') }}</li>
							<li>{{ translate('withdraw_commissions_answer_4') }}</li>
							<li>{{ translate('withdraw_commissions_answer_5') }}</li>
							<li>{{ translate('withdraw_commissions_answer_6') }}</li>
							<li>{{ translate('withdraw_commissions_answer_7') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('see-transferred-funds')"
			ref="see-transferred-funds"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.see-transferred-funds
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('see_transferred_funds_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="see-transferred-funds"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('see_transferred_funds_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('merchant-code-ewallet')"
			ref="merchant-code-ewallet"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.merchant-code-ewallet
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('merchant_code_ewallet_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="merchant-code-ewallet"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('merchant_code_ewallet_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('ewallet-no-verified')"
			ref="ewallet-no-verified"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.ewallet-no-verified
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('ewallet_cannot_be_verified_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="ewallet-no-verified"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('ewallet_cannot_be_verified_answer') }}
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('wallet-password')"
			ref="wallet-password"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.wallet-password
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('wallet_password_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="wallet-password"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text v-html="translate('wallet_password_answer')" />
				</b-card-body>
			</b-collapse>
		</b-card>
		<b-card
			v-show="refsLoaded && showCard('change-wallet-password')"
			ref="change-wallet-password"
			no-body
			class="mb-1">
			<b-card-header
				header-tag="header"
				class="p-1"
				role="tab">
				<b-button
					v-b-toggle.change-wallet-password
					block
					class="text-left font-weight-bold"
					href="#"
					variant="default">
					{{ translate('change_wallet_password_ask') }}
					<span class="when-opened"><i class="fas fa-angle-up rotate-icon mr-2" /></span>
					<span class="when-closed"><i class="fas fa-angle-down rotate-icon mr-2" /></span>
				</b-button>
			</b-card-header>
			<b-collapse
				id="change-wallet-password"
				accordion="my-accordion"
				role="tabpanel">
				<b-card-body>
					<b-card-text>
						{{ translate('change_wallet_password_answer') }}
						<ol>
							<li>{{ translate('change_wallet_password_answer_1') }}</li>
							<li>{{ translate('change_wallet_password_answer_2') }}</li>
							<li>{{ translate('change_wallet_password_answer_3') }}</li>
							<li>{{ translate('change_wallet_password_answer_4') }}</li>
							<li>{{ translate('change_wallet_password_answer_5') }}</li>
							<li>{{ translate('change_wallet_password_answer_6') }}</li>
							<li>{{ translate('change_wallet_password_answer_7') }}</li>
						</ol>
					</b-card-text>
				</b-card-body>
			</b-collapse>
		</b-card>
	</div>
</template>

<script>
// import { customer, preDistributor, admin } from '@/settings/Roles';
import {
	Faq as messages,
} from '@/translations';
import SearchMixin from './mixins/Search';

export default {
	name: 'FaqCommissionsWallets',
	messages,
	mixins: [SearchMixin],
	props: {
		searchTerm: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			// customer,
			// preDistributor,
			// admin,
		};
	},
	methods: {
		showCard(ref) {
			return this.hasSearchTerm(ref, this.searchTerm);
		},
	},
};
</script>
